import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom';
import Dashboard from './Screens/Dashboard';
import '../src/assets/css/Style.css'
import jwt_decode from "jwt-decode";

import ResetPassword from './Screens/ResetPassword';
import Profile from './Screens/Profile';
import Subscription from './Screens/Subscription';

import AddSubscription from './Screens/AddSubscription';
import Plans from './Screens/Plans';
import AddPlans from './Screens/AddPlans';
import Login from './Screens/Login';
import ForgotPassword from './Screens/ForgotPassword';

import isEmpty from "is-empty";
// lib
import { getAuthToken } from "./lib/localStorage";
// import constant

import { useDispatch } from 'react-redux';
// import  {toast,ToastContainer} from 'react-toastify';
import { Toaster } from 'react-hot-toast';
import { PrivateRoute } from './PrivateRoutes';
import { AuthRoute } from './AuthRoute';
import TotalUsers from './Screens/TotalUsers';
import Groups from './Screens/Groups';
import Interests from './Screens/Category';
import TermsAndConditions from './Screens/TermsAndConditions';
import About from './Screens/About';
import PrivacyPolicy from './Screens/PrivacyPolicy';
import Category from './Screens/Category';
import SubCategory from './Screens/SubCategory';
import PlanDetails from './Screens/PlanDetails';
import Gender from './Screens/Gender';
import Emailupdatelists from './Screens/emailupdates/emailupdatelists'
import Sendemail from './Screens/emailupdates/sendemail'
import Addemailupdate from './Screens/emailupdates/addemailupdate'
import Editcms from "./Screens/Cms/editcms"
import Cmslists from "./Screens/Cms/cmslists"
import Addfaq from "./Screens/Faq/addfaq"
import Editfaq from "./Screens/Faq/editfaq"
import Listfaq from "./Screens/Faq/faqlists"
import HelpLists from './Screens/helplists';
import Feedbacklists from "./Screens/feedbacklists"
import EmailTemplateEdit from './Screens/emailtemplate/emailtemplateEdit';
import EmailTemplateList from './Screens/emailtemplate/emailtemplatelist';
import Userplan from './Screens/UserplanDetail/userplanlist'
import Eventlists from "./Screens/Events/eventlists"
import ReportList from "./Screens/Reportlist"
import AuthForgotPassword from './Screens/AuthForgotPassword';
import Groupcategory from './Screens/Groupcategorylist';
import HobbiesLists from './Screens/Hobbieslists';

function App() {

  // console.log=()=>{};
  // console.warning = ()=>{}
  // console.error=()=>{}
  // console.info =()=>{}

  const dispatch = useDispatch()
  useEffect(() => {

    let token = getAuthToken();
    if (!isEmpty(token)) {
      token = token.replace("Bearer ", "");
      const decoded = jwt_decode(token);
      console.log("decodeddecoded", decoded);
      if (decoded) {
        dispatch({
          type: "userdata",

          userdata: {
            isAuth: true,
            userId: decoded._id,
            email: decoded.email,
            name: decoded.name,
            hobby_limit: decoded.hobby_limit,
            eventimagelimit: decoded.eventImage_limit
          },
        });
      }
    }

  }, [])

  return (
    <>
      <Toaster />
      <Routes>
        <Route path='/' element={
          <AuthRoute >
            <Login />
          </AuthRoute >
        } />
        <Route path='/login' element={

          <Login />

        } />
        <Route path='/forgot-Password' element={<AuthForgotPassword />} />

        <Route path='/forgotPassword' element={<ForgotPassword />} />


        <Route exact path='/dashboard' element={
          <Dashboard />

        } />
    


        <Route exact path='/profile' element={
          <PrivateRoute >
            <Profile />
          </PrivateRoute >
        } />



        <Route exact path='/subscription' element={
          <PrivateRoute >
            <Subscription />
          </PrivateRoute >
        } />

        <Route exact path='/resetPassord' element={
          <PrivateRoute >
            <ResetPassword />
          </PrivateRoute >
        } />


        <Route exact path='/addSubscription' element={
          <PrivateRoute >
            <AddSubscription />
          </PrivateRoute >
        } />

        <Route exact path='/plans' element={
          <PrivateRoute >
            <Plans />
          </PrivateRoute >
        } />

        <Route exact path='/addPlans' element={
          <PrivateRoute >
            <AddPlans />
          </PrivateRoute >
        } />

        <Route exact path='/users' element={
          <PrivateRoute >
            <TotalUsers />
          </PrivateRoute >
        } />
        <Route exact path='/groups' element={
          <PrivateRoute >
            <Groups />
          </PrivateRoute >
        } />
        <Route exact path='/category' element={
          <PrivateRoute >
            <Category />
          </PrivateRoute >
        } />
        <Route exact path='/sub-category' element={
          <PrivateRoute >
            <SubCategory />
          </PrivateRoute >
        } />
        <Route exact path='/terms-and-conditions' element={
          <PrivateRoute >
            <TermsAndConditions />
          </PrivateRoute >
        } />
        <Route exact path='/about' element={
          <PrivateRoute >
            <About />
          </PrivateRoute >
        } />
        <Route exact path='/privacy-policy' element={
          <PrivateRoute >
            <PrivacyPolicy />
          </PrivateRoute >
        } />
        <Route exact path='/plan-details' element={
          <PrivateRoute >
            <PlanDetails />
          </PrivateRoute >
        } />
        <Route exact path='/gender' element={
          <PrivateRoute >
            <Gender />
          </PrivateRoute >
        } />

        <Route exact path='/emailupdatelists' element={
          <PrivateRoute >
            <Emailupdatelists />
          </PrivateRoute >
        } />
        <Route exact path='/sendemail' element={
          <PrivateRoute >
            <Sendemail />
          </PrivateRoute >
        } />
        <Route exact path='/addemailupdate' element={
          <PrivateRoute >
            <Addemailupdate />
          </PrivateRoute >
        } />

        <Route exact path='/editcms' element={
          <PrivateRoute >
            <Editcms />
          </PrivateRoute >
        } />
        <Route exact path='/cmslists' element={
          <PrivateRoute >
            <Cmslists />
          </PrivateRoute >
        } />


        <Route exact path='/addfaq' element={
          <PrivateRoute >
            <Addfaq />
          </PrivateRoute >
        } />
        <Route exact path='/editfaq' element={
          <PrivateRoute >
            <Editfaq />
          </PrivateRoute >
        } />
        <Route exact path='/faqlists' element={
          <PrivateRoute >
            <Listfaq />
          </PrivateRoute >
        } />

        <Route exact path='/helplists' element={
          <PrivateRoute >
            <HelpLists />
          </PrivateRoute >
        } />

        <Route exact path='/feedbacklists' element={
          <PrivateRoute >
            <Feedbacklists />
          </PrivateRoute >
        } />

        <Route exact path='/emailtemplatelist' element={
          <PrivateRoute >
            <EmailTemplateList />
          </PrivateRoute >
        } />

        <Route exact path='/emailtemplateEdit/:slug' element={
          <PrivateRoute >
            <EmailTemplateEdit />
          </PrivateRoute >
        } />
        <Route exact path='/userplanlist' element={
          <PrivateRoute >
            <Userplan />
          </PrivateRoute >
        } />
        <Route exact path='/eventlists' element={
          <PrivateRoute >
            <Eventlists />
          </PrivateRoute >
        } />
        <Route exact path='/reportlist' element={
          <PrivateRoute >
            <ReportList />
          </PrivateRoute >
        } />

<Route exact path='/Groupcategorylist' element={
          <PrivateRoute >
            <Groupcategory />
          </PrivateRoute >
        } />

<Route exact path='/Hobbieslists' element={
          <PrivateRoute >
            <HobbiesLists />
          </PrivateRoute >
        } />
      </Routes>


    </>
  );
}

export default App;