import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import Select from 'react-select'
import { adminAddSubcategory, adminAddcategory, categorylist } from '../api/api';
import toast from 'react-hot-toast';
import { isEmpty } from '../config/common';

function EditSubcategory({ show, handleClose, EditData, editfunc }) {
    console.log("EditData", EditData);

    const initial = {
        "subcategory": ""
    }
    const options = [
        {
            value: "active", label: "Active"
        },
        {
            value: "inactive", label: "Inactive"
        }
    ]
    const [subcategory, setSubcategory] = useState(initial)
    console.log("subcategory", subcategory);
    const [categorylists, setCategorylists] = useState([])
    const [categoryId, setCategoryId] = useState({ label: EditData?.categoryId?.category, value: EditData?.categoryId?._id })
    console.log("categoryIdcategoryId", categoryId, categorylists);

    useEffect(() => {
        setSubcategory(EditData?.subcategory)
        GetCategogylists()
        setCategoryId({ label: EditData?.categoryId?.category, value: EditData?.categoryId?._id })
    }, [EditData])
    const [ValidateError, SetValidateError] = useState('')
    const Validation = () => {

        let ValidateError = {}


        if (!subcategory) {
            ValidateError.subcategory = "subcategory name is required";
        }


        SetValidateError(ValidateError)
        return ValidateError
    }
    const Editcategory = async () => {
        try {
            var errors = {};
            var value = Validation();

            if (!isEmpty(value)) {
                console.log("value", value);
                SetValidateError(value);
                toast.error(Object.values(value));
            } else {
                if (Object.keys(errors).length == 0) {
                    let payload = {
                        id: EditData?._id,
                        subcategoryname: subcategory,
                        categoryId: categoryId ? categoryId.value : "",
                        type: "edit"
                    }
                    let resp = await adminAddSubcategory(payload)
                    console.log("payload", resp);
                    if (resp.status) {
                        toast.success(resp.message)
                        editfunc()
                        handleClose()

                    }
                    else {
                        toast.error(resp.message)

                    }
                }
            }
        } catch (error) {
            console.log("error", error);
        }
    }




    const GetCategogylists = async () => {
        try {


            let resp = await categorylist()
            console.log("responses", resp);
            if (resp.status) {
                const formattedCategories = resp.data.map((category) => ({
                    label: category.category, // Using the 'category' field for label
                    value: category._id, // Using the '_id' field as the value
                }));

                console.log("formattedCategories", formattedCategories)
                setCategorylists(formattedCategories);
            }
            else {
                toast.error(resp.message)

            }


        } catch (error) {
            console.log("error", error);
        }
    }


    return (
        <>
            <Modal centered className='cmn_modal'
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size='md'
            >
                <Modal.Body>
                    <div className='cmn_modal_header d-flex justify-content-between align-items-center'>
                        <p className='cmn_modal_title'>Edit Subcategory</p>
                        <button className='cmn_modal_closer rounded-5' onClick={handleClose}><i class="fa-solid fa-xmark" /></button>
                    </div>


                    <div className='rp_singleinput_holder mt-3 mb-3'>
                        <p className='mdl_label mb-2'>Select Category</p>
                        <div className='select_wrapper w-100'>
                            <Select
                                classNamePrefix="custom_rct_slt"
                                isSearchable={false}
                                value={categoryId}
                                options={categorylists}
                                onChange={(e) => setCategoryId(e)}
                            />
                        </div>
                    </div>
                    <div className='rp_input_holder w-75  rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2 mt-3'>

                        <input type='text' placeholder='Enter the Subcategory' id="Subcategory" name="Subcategory" value={subcategory} onChange={(e) => setSubcategory(e.target.value)} className='rp_singleInput flex-grow-1'
                        />
                    </div>
                    {ValidateError && ValidateError.subcategory && <span className="error_msg">{ValidateError.subcategory}</span>}

                    {/* <div className='mt-3'>
                        <Select options={options} />
                    </div> */}
                    <button className='orange_primary_btn mt-3' onClick={() => Editcategory()}>Update</button>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default EditSubcategory